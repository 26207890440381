import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MessagesConstants } from '../../constants/app.constants';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { UserInfo } from 'src/app/models/user-info.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})

export class AppHeaderComponent implements OnInit {
  public loggedInUser: any;
  public navigationSelected = {};
  public navItems: any[] = [
    { "label": "Home", "icon": "home", "key": "home-page" },
    { "label": "Contact", "icon": "mail", "key": "contact-us" }
  ];
  public settings: any[] = [
    { "label": "Log Out", "icon": "power_settings_new", "key": "log-out" },
    { "isDivider": true },
    { "label": "App Version: 1.2.0" }
  ];

  constructor(
    public router: Router,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    this.utilityService.getUserInfo().subscribe((rs) => {
      this.loggedInUser = rs as UserInfo;
    });
  }

  @HostListener('bhEventSelected', ['$event'])
  public subNavSelected({ detail }: any): void {
    const { item, clicked } = detail.keys;
    if (!!clicked) {
      this.handleNavigation(clicked.item);
    } {
      this.navigationSelected = { item };
      this.handleNavigation(item);
    }
  }

  public handleNavigation(menu: any): void {
    switch (menu) {
      case 'home-page':
        this.navigateToHome();
        break;
      case 'contact-us':
        this.openMail();
        break;
      case 'log-out':
        this.logOut();
        break;
      default:
        break;
    }
  }

  navigateToHome() {
    this.router.navigate(['/dull-list'])
      .then(() => {
        window.location.reload();
      });
  }

  openMail() {
    const emailId = MessagesConstants?.supportEmailId;
    const subjectData = MessagesConstants?.mailSubject;
    const bodyContent = `&body=${encodeURIComponent(MessagesConstants?.emailBody)}`;
    const mailToLink = `mailto: ${emailId} ?subject=  ${subjectData}  ${bodyContent}`;

    const windowObj = window.open(mailToLink, 'emailWindow');
    if (windowObj && windowObj.open() && !windowObj.closed) {
      window.close();
    }
  }

  public logOut(): void {
    this._oktaAuth.signOut();
    localStorage.clear();
  }

}
